import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"

const useLangs = (location, languages) => {
  const url = location
  const { langs, defaultLangKey } = languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const localLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
  let langsMenu = getLangs(langs, langKey, getUrlForLang(localLink, url)).map(
    item => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, "/"),
    })
  )
  let i18Translations = require(`../locales/translations/${langKey}`)

  return {
    langKey,
    i18Translations,
    langsMenu,
    localLink,
  }
}

export default useLangs
