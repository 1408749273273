import React, { useState } from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { FormattedMessage } from "react-intl"

import DropdownIcon from "../../images/icons/dropdown-icon.inline.svg"

const StyledMenu = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: ${props => (props.open ? "flex" : "none")};
    flex: 1;
    flex-direction: column;
    flex-basis: 100%;
    order: 3;
  }
`

const Link = styled(GatsbyLink)`
  display: inline-block;
  padding: 24px 12px;
  text-decoration: none;
  color: ${props => props.theme.colors.dark100};

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 16px 0px;
  }
`

const LinkBtn = styled(Link)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.light100};
  font-weight: 700;
  padding: 16px 40px;
  border-radius: 4px;
  margin-left: 12px;
  transition: all 0.2s ease;
  box-shadow: 0 12px 25px rgba(206, 50, 50, 0.2);

  :hover {
    background-color: #ad1f1f;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    text-align: center;
    margin-left: 0;
    margin-top: 16px;
  }
`

const StyledDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  :hover .dropdown {
    display: block;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: none;
  }
`

const StyledDropdown = styled.div`
  position: absolute;
  display: none;
  z-index: 10;
  min-width: 300px;
  margin-left: 0;
  background-color: ${props => props.theme.colors.light90};
  margin-bottom: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

  a {
    margin-bottom: 0;
    padding: 16px 24px;
    display: block;
    color: ${props => props.theme.colors.dark100};
    border: none;
    text-decoration: none;

    :hover {
      background-color: #e2e2e2;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    min-width: 100%;
  }
`

const StyledDropdownBtn = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${props => props.theme.colors.dark100};
    margin-right: 8px;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledDropdownWrapperMobile = styled.div`
  cursor: pointer;
  position: relative;
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: inline-block;
    .dropdown-machinery {
      position: relative;
      display: ${props => (props.dropdownMachinery ? "block" : "none")};
      box-shadow: none;
    }

    .dropdown-products {
      position: relative;
      display: ${props => (props.dropdownProducts ? "block" : "none")};
      box-shadow: none;
    }
    .dropdown-about {
      position: relative;
      display: ${props => (props.dropdownAbout ? "block" : "none")};
      box-shadow: none;
    }
  }
`

const Menu = ({ open, localLink }) => {
  const [dropdownMachinery, toggleMachineryDropdown] = useState(false)
  const [dropdownProducts, toggleProductsDropdown] = useState(false)
  const [dropdownAbout, toggleAboutDropdown] = useState(false)

  return (
    <StyledMenu open={open}>
      <StyledDropdownWrapper>
        <StyledDropdownBtn>
          <span>
            <FormattedMessage id="about" />
          </span>
          <DropdownIcon />
        </StyledDropdownBtn>
        <StyledDropdown className="dropdown">
          <Link to={`${localLink}about`}>
            <FormattedMessage id="history" />
          </Link>
          <Link to={`${localLink}group-structure`}>
            <FormattedMessage id="groupStructure" />
          </Link>
        </StyledDropdown>
      </StyledDropdownWrapper>
      <StyledDropdownWrapperMobile
        dropdownAbout={dropdownAbout}
        onClick={e => toggleAboutDropdown(!dropdownAbout)}
      >
        <StyledDropdownBtn>
          <span>
            <FormattedMessage id="about" />
          </span>
          <DropdownIcon />
        </StyledDropdownBtn>
        <StyledDropdown className="dropdown-about">
          <Link to={`${localLink}about`}>
            <FormattedMessage id="about" />
          </Link>
          <Link to={`${localLink}group-structure`}>
            <FormattedMessage id="groupStructure" />
          </Link>
        </StyledDropdown>
      </StyledDropdownWrapperMobile>
      <StyledDropdownWrapper>
        <StyledDropdownBtn>
          <span>
            <FormattedMessage id="machinery" />
          </span>
          <DropdownIcon />
        </StyledDropdownBtn>
        <StyledDropdown className="dropdown">
          <Link to={`${localLink}machinery/transfer-presses`}>
            <FormattedMessage id="transferPresses" />
          </Link>
          <Link to={`${localLink}machinery/progressive-presses`}>
            <FormattedMessage id="progressivePresses" />
          </Link>
          <Link
            to={`${localLink}machinery/single-operation-and-hydraulic-presses`}
          >
            <FormattedMessage id="singleOperationHydraulicPresses" />
          </Link>
          <Link to={`${localLink}machinery/multi-slide-bending-machine`}>
            <FormattedMessage id="multiSlideBendingMachine" />
          </Link>
          <Link to={`${localLink}machinery/die-and-tooling-workshop`}>
            <FormattedMessage id="dieToolingWorkshop" />
          </Link>
          <Link to={`${localLink}machinery/secondary-processing`}>
            <FormattedMessage id="secondaryProcessing" />
          </Link>
        </StyledDropdown>
      </StyledDropdownWrapper>
      <StyledDropdownWrapperMobile
        dropdownMachinery={dropdownMachinery}
        onClick={e => toggleMachineryDropdown(!dropdownMachinery)}
      >
        <StyledDropdownBtn>
          <span>
            <FormattedMessage id="machinery" />
          </span>
          <DropdownIcon />
        </StyledDropdownBtn>
        <StyledDropdown className="dropdown-machinery">
          <Link to={`${localLink}machinery/transfer-presses`}>
            <FormattedMessage id="transferPresses" />
          </Link>
          <Link to={`${localLink}machinery/progressive-presses`}>
            <FormattedMessage id="progressivePresses" />
          </Link>
          <Link
            to={`${localLink}machinery/single-operation-and-hydraulic-presses`}
          >
            <FormattedMessage id="singleOperationHydraulicPresses" />
          </Link>
          <Link to={`${localLink}machinery/multi-slide-bending-machine`}>
            <FormattedMessage id="multiSlideBendingMachine" />
          </Link>
          <Link to={`${localLink}machinery/die-and-tooling-workshop`}>
            <FormattedMessage id="dieToolingWorkshop" />
          </Link>
          <Link to={`${localLink}machinery/secondary-processing`}>
            <FormattedMessage id="secondaryProcessing" />
          </Link>
        </StyledDropdown>
      </StyledDropdownWrapperMobile>
      <StyledDropdownWrapper>
        <StyledDropdownBtn>
          <Link
            to={`${localLink}products`}
            style={{ padding: 0, marginRight: 8 }}
          >
            <FormattedMessage id="products" />
          </Link>
          <DropdownIcon />
        </StyledDropdownBtn>
        <StyledDropdown className="dropdown">
          <Link to={`${localLink}products/transfer-parts`}>
            <FormattedMessage id="transferParts" />
          </Link>
          <Link to={`${localLink}products/progressive-parts`}>
            <FormattedMessage id="progressiveParts" />
          </Link>
          <Link to={`${localLink}products/multi-slide-bending-parts`}>
            <FormattedMessage id="multiSlideBendingParts" />
          </Link>
          <Link
            to={`${localLink}products/single-operation-and-hydraulic-pressing-parts`}
          >
            <FormattedMessage id="singleOperationHydraulicPressing" />
          </Link>
          <Link to={`${localLink}products/polished-and-coated-products`}>
            <FormattedMessage id="polishedCoatedProducts" />
          </Link>
          <Link to={`${localLink}products/nc-machining-parts`}>
            <FormattedMessage id="NCMachiningParts" />
          </Link>
          <Link to={`${localLink}products/die-and-tooling-parts`}>
            <FormattedMessage id="DieToolingParts" />
          </Link>
        </StyledDropdown>
      </StyledDropdownWrapper>
      <StyledDropdownWrapperMobile
        dropdownProducts={dropdownProducts}
        onClick={e => toggleProductsDropdown(!dropdownProducts)}
      >
        <StyledDropdownBtn>
          <Link to={`${localLink}products`} style={{ padding: 0 }}>
            <FormattedMessage id="products" />
          </Link>
          <DropdownIcon />
        </StyledDropdownBtn>
        <StyledDropdown className="dropdown-products">
          <Link to={`${localLink}products/transfer-parts`}>
            <FormattedMessage id="transferParts" />
          </Link>
          <Link to={`${localLink}products/progressive-parts`}>
            <FormattedMessage id="progressiveParts" />
          </Link>
          <Link to={`${localLink}products/multi-slide-bending-parts`}>
            <FormattedMessage id="multiSlideBendingParts" />
          </Link>
          <Link
            to={`${localLink}products/single-operation-and-hydraulic-pressing-parts`}
          >
            <FormattedMessage id="singleOperationHydraulicPressing" />
          </Link>
          <Link to={`${localLink}products/polished-and-coated-products`}>
            <FormattedMessage id="polishedCoatedProducts" />
          </Link>
          <Link to={`${localLink}products/nc-machining-parts`}>
            <FormattedMessage id="NCMachiningParts" />
          </Link>
          <Link to={`${localLink}products/die-and-tooling-parts`}>
            <FormattedMessage id="DieToolingParts" />
          </Link>
        </StyledDropdown>
      </StyledDropdownWrapperMobile>
      <Link to={`${localLink}quality`}>
        <FormattedMessage id="quality" />
      </Link>
      <Link to={`${localLink}certificates`}>
        <FormattedMessage id="certificates" />
      </Link>
      <LinkBtn to={`${localLink}contact`}>
        <FormattedMessage id="contact" />
      </LinkBtn>
    </StyledMenu>
  )
}

export default Menu
