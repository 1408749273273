const translations = {
  // Navigation
  about: "About Us",
  history: "History",
  //
  machinery: "Machinery",
  // Machinery Dropdown
  transferPresses: "Transfer Presses",
  progressivePresses: "Progressive Presses",
  singleOperationHydraulicPresses: "Single Operation & Hydraulic Presses",
  multiSlideBendingMachine: "Multi-Slide Bending Machine",
  dieToolingWorkshop: "Die & Tooling Workshop",
  secondaryProcessing: "Secondary Processing",
  //
  products: "Products",
  // Products Dropdown
  transferParts: "Transfer Parts",
  progressiveParts: "Progressive Parts",
  multiSlideBendingParts: "Multi-Slide Bending Parts",
  singleOperationHydraulicPressing:
    "Single Operation & Hydraulic Pressing Parts",
  polishedCoatedProducts: "Polished & Coated Products",
  NCMachiningParts: "NC Machining Parts",
  DieToolingParts: "Die & Tooling Parts",
  //
  quality: "Quality",
  certificates: "Certificates",
  contact: "Contact",
  //
  capabilities: "Capabilities",
  readMore: "Read More",
  viewMore: "View More",
  //
  sewageReport: "Sewage Inspection Report",
  //
  framewayGroup: "Frameway Group",
  location: "Location",
  corporateHeadquarters: "Corporate Headquarters",
  contactUs: "Contact Us",
  sendMessage: "Send Message",
  message: "Message",
  name: "Name",
  email: "Email",
  //
  groupStructure: "Group Structure",
}

module.exports = translations
