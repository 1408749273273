import styled from "styled-components"

export const StyledContainer = styled.div`
  max-width: 1224px;
  padding: 0 12px;
  margin: 0 auto;
`

export const StyledContainerS = styled.div`
  max-width: 968px;
  padding: 0 12px;
  margin: 0 auto;
`
