import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FormattedMessage } from "react-intl"

import useLangs from "../../hooks/useLangs"
import { StyledContainer } from "../containers/index"

const StyledWrapper = styled.footer`
  background-color: ${props => props.theme.colors.primary};
  padding: 64px 0;
`

const StyledFooter = styled(StyledContainer)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;

  a {
    display: block;
    padding: 12px 0;
    color: ${props => props.theme.colors.light100};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: 1fr;
  }
`

const StyledMenuHeader = styled.p`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  color: ${props => props.theme.colors.light100};
`

const Footer = ({ location, site }) => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "iso.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { localLink } = useLangs(location.pathname, site.siteMetadata.languages)

  return (
    <StyledWrapper>
      <StyledFooter>
        <div>
          <StyledMenuHeader style={{ fontSize: 24, lineHeight: "24px" }}>
            Frameway Group
          </StyledMenuHeader>
          <div>
            <a href="mailto:FWGROUP@FRAMEWAY.COM">fwgroup@frameway.com</a>
            <a href="mailto:MARKETING@FRAMEWAY.COM">marketing@frameway.com</a>
          </div>
        </div>
        <div>
          <StyledMenuHeader>
            <FormattedMessage id="machinery" />
          </StyledMenuHeader>
          <div>
            <Link to={`${localLink}machinery/transfer-presses`}>
              <FormattedMessage id="transferPresses" />
            </Link>
            <Link to={`${localLink}machinery/progressive-presses`}>
              <FormattedMessage id="progressivePresses" />
            </Link>
            <Link
              to={`${localLink}machinery/single-operation-and-hydraulic-presses`}
            >
              <FormattedMessage id="singleOperationHydraulicPresses" />
            </Link>
            <Link to={`${localLink}machinery/multi-slide-bending-machine`}>
              <FormattedMessage id="multiSlideBendingMachine" />
            </Link>
            <Link to={`${localLink}machinery/die-and-tooling-workshop`}>
              <FormattedMessage id="dieToolingWorkshop" />
            </Link>
            <Link to={`${localLink}machinery/secondary-processing`}>
              <FormattedMessage id="secondaryProcessing" />
            </Link>
          </div>
        </div>
        <div>
          <StyledMenuHeader>
            <FormattedMessage id="products" />
          </StyledMenuHeader>
          <div>
            <Link to={`${localLink}products/transfer-parts`}>
              <FormattedMessage id="transferParts" />
            </Link>
            <Link to={`${localLink}products/progressive-parts`}>
              <FormattedMessage id="progressiveParts" />
            </Link>
            <Link to={`${localLink}products/multi-slide-bending-parts`}>
              <FormattedMessage id="multiSlideBendingParts" />
            </Link>
            <Link
              to={`${localLink}products/single-operation-and-hydraulic-pressing-parts`}
            >
              <FormattedMessage id="singleOperationHydraulicPressing" />
            </Link>
            <Link to={`${localLink}products/polished-and-coated-products`}>
              <FormattedMessage id="polishedCoatedProducts" />
            </Link>
            <Link to={`${localLink}products/nc-machining-parts`}>
              <FormattedMessage id="NCMachiningParts" />
            </Link>
            <Link to={`${localLink}products/die-and-tooling-parts`}>
              <FormattedMessage id="DieToolingParts" />
            </Link>
          </div>
        </div>
        <div>
          <Img fluid={image.file.childImageSharp.fluid} />
        </div>
      </StyledFooter>
    </StyledWrapper>
  )
}

export default Footer
