import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

const StyledImageWrapper = styled(Link)`
  display: inline-block;
  width: 100%;
  max-width: 188px;
  padding: 40px 0;

  img {
    margin-bottom: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 24px 0;
  }
`

const Logo = ({ localLink }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 330, maxHeight: 65) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledImageWrapper to={localLink} aria-label="Frameway logo">
      <Img fluid={data.file.childImageSharp.fluid} />
    </StyledImageWrapper>
  )
}

export default Logo
