import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { IntlProvider } from "react-intl"

import theme from "../../styles/theme"
import GlobalStyles from "../../styles/globalStyles"
import useLangs from "../../hooks/useLangs"
import Navigation from "../navigation/index"
import SelectLanguage from "../selectLanguage/index"
import Footer from "../footer/index"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          languages {
            langs
            defaultLangKey
          }
        }
      }
    }
  `)

  const { langKey, i18Translations, langsMenu, localLink } = useLangs(
    location.pathname,
    data.site.siteMetadata.languages
  )

  return (
    <>
      <IntlProvider locale={langKey} messages={i18Translations}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SelectLanguage langs={langsMenu} />
          <Navigation localLink={localLink} />
          <main>{children}</main>
          <Footer site={data.site} location={location} />
          <SelectLanguage langs={langsMenu} />
        </ThemeProvider>
      </IntlProvider>
    </>
  )
}

export default Layout
