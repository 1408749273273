import React, { useState } from "react"
import styled from "styled-components"

import Logo from "./logo"
import Menu from "./menu"
import MenuBtn from "./menuBtn"

import { StyledContainer } from "../containers/index"

const StyledNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`

const Navigation = ({ localLink }) => {
  const [open, toggleOpen] = useState(false)

  return (
    <StyledContainer as="header">
      <StyledNavigation>
        <Logo localLink={localLink} />
        <Menu open={open} localLink={localLink} />
        <MenuBtn toggleOpen={toggleOpen} open={open} />
      </StyledNavigation>
    </StyledContainer>
  )
}

export default Navigation
