import React from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

import { StyledContainer } from "../containers/index"

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.colors.light90};
`

const StyledLangsMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.dark100};
  text-decoration: none;
  opacity: ${props => (props.active === "true" ? "1" : ".6")};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px 8px;
  display: inline-block;

  :hover {
    text-decoration: underline;
    opacity: 1;
  }
`

const SelectLanguage = ({ langs }) => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledLangsMenu>
          <div>
            {/* <Link to={langs[2].link} active={`${langs[2].selected}`}>
              繁體中文
            </Link>
            <Link to={langs[1].link} active={`${langs[1].selected}`}>
              简体中文
            </Link> */}
            <Link to={langs[0].link} active={`${langs[0].selected}`}>
              English
            </Link>
          </div>
        </StyledLangsMenu>
      </StyledContainer>
    </StyledWrapper>
  )
}

export default SelectLanguage
