const translations = {
  // Navigation
  about: "關於我們",
  history: "歷史",
  //
  machinery: "機械",
  // Machinery Dropdown
  transferPresses: "轉移壓力機",
  progressivePresses: "漸進壓力機",
  singleOperationHydraulicPresses: "單動液壓機",
  multiSlideBendingMachine: "多滑彎機",
  dieToolingWorkshop: "模具車間",
  secondaryProcessing: "二次加工",
  //
  products: "產品展示",
  // Products Dropdown
  transferParts: "轉移零件",
  progressiveParts: "漸進零件",
  multiSlideBendingParts: "多滑塊彎曲零件",
  singleOperationHydraulicPressing: "單操作和液壓機",
  polishedCoatedProducts: "拋光和塗層產品",
  NCMachiningParts: "數控加工零件",
  DieToolingParts: "模具零件",
  //
  quality: "質量",
  certificates: "證明書",
  contact: "聯繫我們",
  //
  capabilities: "能力",
  readMore: "閱讀更多",
  viewMore: "點擊查看更多",
  sewageReport: "污水檢查報告",
  //
  framewayGroup: "譽威集團",
  location: "位置",
  corporateHeadquarters: "公司總部",
  contactUs: "聯繫我們",
  sendMessage: "發信息",
  message: "信息",
  name: "名稱",
  email: "電子郵件",
  //
  framewayGroupStructure: "框架組結構",
  //
  groupStructure: "集團結構",
}

module.exports = translations
