import React from "react"
import styled from "styled-components"

import MenuIcon from "../../images/icons/menu-icon.inline.svg"

const StyledMenuBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: flex;
  }
`

const MenuBtn = ({ toggleOpen, open }) => {
  return (
    <StyledMenuBtn onClick={e => toggleOpen(!open)} aria-label="Mobile Menu">
      <MenuIcon />
    </StyledMenuBtn>
  )
}

export default MenuBtn
