const translations = {
  // Navigation
  about: "关于我们",
  history: "历史",
  //
  machinery: "机械",
  // Machinery Dropdown
  transferPresses: "转移压力机",
  progressivePresses: "渐进压力机",
  singleOperationHydraulicPresses: "单动液压机",
  multiSlideBendingMachine: "多滑弯机",
  dieToolingWorkshop: "模具车间",
  secondaryProcessing: "二次加工",
  //
  products: "产品展示",
  // Products Dropdown
  transferParts: "转移零件",
  progressiveParts: "渐进零件",
  multiSlideBendingParts: "多滑块弯曲零件",
  singleOperationHydraulicPressing: "单操作和液压机",
  polishedCoatedProducts: "抛光和涂层产品",
  NCMachiningParts: "数控加工零件",
  DieToolingParts: "模具零件",
  //
  quality: "质量",
  certificates: "证明书",
  contact: "联系我们",
  //
  capabilities: "能力",
  readMore: "阅读更多",
  viewMore: "点击查看更多",
  //
  sewageReport: "污水检查报告",
  //
  framewayGroup: "譽威集團",
  location: "位置",
  corporateHeadquarters: "公司总部",
  contactUs: "联系我们",
  sendMessage: "发信息",
  message: "信息",
  name: "名称",
  email: "电子邮件",
  //
  groupStructure: "集团结构",
}

module.exports = translations
