const theme = {
  colors: {
    primary: "#CE3232",
    light100: "#F8F8F8",
    light90: "#f3f4f9",
    dark100: "#1F1B1B",
    black: "#000000",
    white: "#ffffff",
  },
  breakpoints: {
    xl: "1200px",
    l: "992px",
    m: "768px",
    s: "576px",
  },
}

export default theme
